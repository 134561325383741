import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { appURLs } from "@/app/app-urls.mjs";
import { TierlistContainer } from "@/game-deadlock/Tierlist.style.jsx";
import Assets from "@/game-deadlock/utils/Assets.mjs";
import { heroWinrateColor } from "@/game-deadlock/utils/hero-winrate-color.mjs";
import { localeString } from "@/game-deadlock/utils/localestring.mjs";
import { useHeroesTierlist } from "@/game-deadlock/utils/use-heroes-tierlist.mjs";
import getTierIcon from "@/shared/get-tier-icon.mjs";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";

const TIERLIST_TIER_TITLES = {
  1: ["deadlock:tierlist.tier1Heroes", "Tier 1 Heroes"],
  2: ["deadlock:tierlist.tier2Heroes", "Tier 2 Heroes"],
  3: ["deadlock:tierlist.tier3Heroes", "Tier 3 Heroes"],
  4: ["deadlock:tierlist.tier4Heroes", "Tier 4 Heroes"],
};

export default function Tierlist() {
  const { t } = useTranslation();
  const { loading: tierlistLoading, stats: tierlist } = useHeroesTierlist();

  const firstHero = Object.values(tierlist)?.[1]?.[0];
  const totalMatches = firstHero?.total_num_matches;
  const totalPlayers = firstHero?.total_num_match_players;
  const latestPatch = firstHero?.patch;
  const diffInTime: number =
    latestPatch && new Date().getTime() - new Date(latestPatch).getTime();
  const diffInDays: number = Math.floor(diffInTime / (1000 * 60 * 60 * 24));

  return (
    <PageContainer>
      <PageHeader
        imageRound
        image={`${appURLs.CDN}/blitz/deadlock/logos/deadlock-logo-circle-240-240.webp`}
        title={t(
          "deadlock:tierlistPage.title",
          "Deadlock Hero Tier List and Stats",
        )}
      />
      <div
        className="type-caption--bold shade1"
        style={{
          display: "flex",
          gap: "var(--sp-2)",
          opacity: totalMatches ? 1 : 0.38,
        }}
      >
        {tierlistLoading ? (
          <span>{t("", "Loading...")}</span>
        ) : latestPatch ? (
          <>
            <span>
              <Trans i18nKey="common:stats.patchTimeAgo">
                Patch:{" "}
                <strong style={{ color: "var(--shade0)" }}>
                  {diffInDays.toLocaleString()} Days Ago
                </strong>
              </Trans>
            </span>
            {" • "}
            <span>
              {t(
                "common:stats.matchesCountPlayed",
                "{{matchesPlayed}} Matches Played",
                {
                  matchesPlayed: localeString(totalMatches),
                },
              )}
            </span>
            {" • "}
            <span>
              {t(
                "deadlock:stats.plaeyrsCountAggregated",
                "{{playersCount}} Players Aggregated",
                {
                  playersCount: localeString(totalPlayers),
                },
              )}
            </span>
          </>
        ) : null}
      </div>
      {tierlistLoading ? (
        <Card loading style={{ height: 800 }} />
      ) : (
        <Card className={TierlistContainer()}>
          {Object.keys(tierlist).map((tier) => {
            const heroesList = tierlist[tier];
            const TierIcon = getTierIcon(tier);

            if (!TierIcon) return null;
            return (
              <div
                key={tier}
                className="tier"
                style={{ "--tier-color": `var(--tier${tier})` }}
              >
                <div className="tier-icon--container">
                  <TierIcon width="48" height="48" />
                  {TIERLIST_TIER_TITLES[tier] && (
                    <span className="tier-title type-subtitle--bold">
                      {t(...TIERLIST_TIER_TITLES[tier])}
                    </span>
                  )}
                </div>
                <ol className="hero-grid">
                  {heroesList.map((hero) => {
                    return (
                      <li key={hero.heroId}>
                        <a
                          href={`/deadlock/heroes/${hero.heroName}`}
                          className="hero-grid-item"
                        >
                          <div className="hero-img">
                            <img
                              src={Assets.getHeroPortrait(hero.heroId)}
                              width="64"
                              height="87"
                              alt={
                                hero.heroName
                                  ? t(
                                      "deadlock:heroImageAltWithName",
                                      "{{heroName}} Deadlock Hero",
                                      {
                                        heroName: hero.heroName,
                                      },
                                    )
                                  : t(
                                      "deadlock:heroImageAltNoName",
                                      "Deadlock Hero",
                                    )
                              }
                            />
                          </div>
                          <div className="hero-meta">
                            <span className="type-title--bold">
                              {hero.heroName}
                            </span>
                            <span className="shade1 type-caption--semi">
                              <span
                                className="shade1 type-caption--bold"
                                style={{
                                  color: heroWinrateColor(hero.win_rate),
                                }}
                              >
                                {localeString(hero.win_rate, true, 1)}
                              </span>{" "}
                              {t("lol:winRate", "Win Rate")}
                            </span>
                            {/* <span className="shade1 type-caption--semi">
                            {t(
                                "common:stats.percentAppearance",
                                "{{percent}} Appearance",
                                {
                                  percent: hero.appearance_rate.toLocaleString(
                                    getLocale(),
                                    {
                                      minimumFractionDigits: 1,
                                      maximumFractionDigits: 1,
                                      style: "percent",
                                    },
                                  ),
                                },
                              )}
                          </span> */}
                            <span className="shade1 type-caption--semi">
                              {t("common:stats.kda", "{{ kda, number }} KDA", {
                                kda: localeString(hero.avg_kda, false, 1),
                              })}
                            </span>
                          </div>
                        </a>
                      </li>
                    );
                  })}
                </ol>
              </div>
            );
          })}
        </Card>
      )}
    </PageContainer>
  );
}

export function meta() {
  return {
    title: [
      "deadlock:meta.tierlist.title",
      "Deadlock Tier list, Win Rates, Pick Rates, and more – Blitz Deadlock",
    ],
    description: [],
  };
}
