import { css } from "goober";

export const TierlistContainer = () => css`
  & {
    display: grid;
    gap: var(--sp-12);
  }

  .tier {
    display: flex;
    align-items: flex-start;
    gap: var(--sp-6);
  }
  .tier-title {
    display: none;
  }
  .hero-grid {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: var(--sp-8);
    row-gap: var(--sp-4);
  }
  .hero-grid-item {
    display: flex;
    align-items: center;
    gap: var(--sp-3);

    &:hover {
      .hero-img {
        filter: brightness(1.5);
      }
    }
  }
  .hero-img {
    position: relative;
    background: linear-gradient(to bottom, var(--shade5-75), var(--shade7-50));
    border-radius: var(--br-lg);
    border: 1px solid var(--tier-color);
    transition: filter var(--transition);

    img {
      width: var(--sp-16);
      height: auto;
      aspect-ratio: 56 / 76;
      border-radius: var(--br-lg);
    }
  }
  .hero-meta {
    display: grid;
  }

  @container content-card (inline-size <= 850px) {
    .hero-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @container content-card (inline-size <= 650px) {
    .hero-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @container content-card (inline-size <= 550px) {
    .card-frame {
      --padd: var(--sp-4);
    }
    & {
      gap: var(--sp-8);
    }
    .tier {
      display: grid;
      gap: var(--sp-2);

      svg {
        width: var(--sp-8);
        height: var(--sp-8);
      }
    }
    .tier-icon--container {
      position: relative;
      display: flex;
      align-items: center;
      gap: var(--sp-2);
      padding: var(--sp-1);

      &::before {
        content: "";
        background: linear-gradient(
          to right,
          color-mix(in hsl, var(--tier-color), transparent 80%),
          transparent
        );
        border-radius: var(--br);
        border: 1px solid color-mix(in hsl, var(--tier-color), transparent 50%);
        flex: 1;
        position: absolute;
        inset: 0;
      }
    }
    .tier-title {
      display: block;
      color: var(--tier-color);
    }
    .hero-grid {
      column-gap: var(--sp-4);
    }
    .hero-img img {
      width: var(--sp-12);
    }
  }
`;
