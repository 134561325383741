import { readState } from "@/__main__/app-state.mjs";
import { isInitial } from "@/__main__/constants.mjs";
import type { DeadlockHeroStats } from "@/game-deadlock/models/heroesStats.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

interface DeadlockHero extends DeadlockHeroStats {
  heroName: string;
}
export function useHeroesTierlist(): {
  loading: boolean;
  stats: Record<string, DeadlockHero[]>;
} {
  const {
    deadlock: { heroes, heroesStats },
  } = useSnapshot(readState);

  return {
    loading: !!heroesStats[isInitial],
    stats: (heroesStats || []).reduce((acc, hero) => {
      const heroName = heroes[hero.heroId]?.heroName || "Unknown";

      if (!acc[hero.tier]) acc[hero.tier] = [];
      acc[hero.tier].push({
        ...hero,
        heroName,
      });

      return acc;
    }, {}) as Record<string, DeadlockHero[]>,
  };
}
